<template>
    <div class="flex justify-center items-center h-screen bg-gray-300 px-6">
      <div class="p-6 max-w-sm w-full bg-white shadow-md rounded-md">
        <div class="flex flex-col justify-center items-center">
          <h2 class="text-gray-700 font-semibold text-2xl">Recuperación de contraseña</h2>
          <p class="text-blue-700 text-justify">
            <faIcon
            icon="exclamation-circle"
            type="fa"
            class="text-blue-700 text-md"
            >
            </faIcon>
            Ingresa el correo asociado a tu cuenta de usuario. Recibirás un correo con 
            </p>
        </div>
        <ForgotPasswordForm class="mt-4"/>
      </div>
	</div>
</template>

<script>
  import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm"

  export default {
    name: "ForgotPasswordView",
    components: {
      ForgotPasswordForm
    },
  }
</script>